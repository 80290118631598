const { merge } = require("webpack-merge")

baseConfig = {
  NODE_ENV: process.env.NODE_ENV || "development",
  isDev: process.env.NODE_ENV === "development",
  isProd: process.env.NODE_ENV === "production",
}

devConfig = {
  UIHost: "http://localhost:8080",
  APIHost: "http://localhost:8000",
  STRIPE_PUB_KEY: "pk_test_ZspLpDG4h5CJ4w4wVnXSu9iN",
}

prodConfig = {
  UIHost: "https://dealscout.io",
  APIHost: "https://api.dealscout.io",
  STRIPE_PUB_KEY: "pk_live_szQ7QbwSjuQlFCsR5tD87J6o",
}

const neededConfig =
  baseConfig.NODE_ENV === "production" ? prodConfig : devConfig

module.exports = merge(baseConfig, neededConfig)
