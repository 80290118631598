import store from "@/store/index"
import { get } from "vuex-pathify"

const mixin = {
  data: function() {
    return {}
  },
  created() {
    // this.$accountAPI.getAccount()
    if (this.isLoggedIn && !this.hasValidSubscription) {
      this.$router.push("/billing")
    }
  },
  computed: {
    isLoggedIn: get("account/isAuthenticated"),
    emailVerified: get("account/isVerified"),
    canViewUsers: function() {
      return this.canDoX(this.myPermissions, "Can View Users")
    },
    canUpdateUsers: function() {
      return this.canDoX(this.myPermissions, "Can Update Users")
    },
    canUpdateRoles: function() {
      return this.canDoX(this.myPermissions, "Can Update Roles")
    },
    canUpdateLocations: function() {
      return this.canDoX(this.myPermissions, "Can Update Locations")
    },
    canManageBilling: function() {
      return this.canDoX(this.myPermissions, "Can Update Billing")
    },
    canTriggerSearches: function() {
      return this.canDoX(this.myPermissions, "Can Trigger Searches")
    },
    myPermissions: function() {
      return store.get("account/roles").flatMap((r) => r.permissions)
    },
    existingStripePlan: function() {
      return this.$store.get("account/stripePlan")
    },
    hasValidSubscription: function() {
      // TODO : cache, time-based expiration.
      let existingPlan = this.$store.get("account/stripePlan")
      return (
        existingPlan.subscriptionStatus === "active" ||
        existingPlan.subscriptionStatus === "trialing"
      )
    },
  },
  methods: {
    canDoX: function(myPermissions, x) {
      // console.log(`checking if can ${x}`)
      // console.log(myPermissions.some((p) => p.name === x))
      // console.log("---")
      return myPermissions.some((p) => p.name === x)
    },
    lazyRedirect: function() {
      setTimeout(() => this.$router.push({ path: "/dashboard" }), 4000)
    },
  },
}

export default mixin
