<template>
  <section class="section">
    <div class="hero-body">
      <h2>The requested page timed out.</h2>
      <router-link to="/">Click here to go to the home page</router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: "TimeoutPage",
  components: {},
  mixins: [],
  data() {
    return {}
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  computed: {},
  methods: {},
  filters: {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
}
</script>

<style lang="scss" scoped></style>
