import Vue from "vue"
import App from "@/App.vue"
import store from "@/store/index"
import router from "@/router/index"
import "@/registerServiceWorker"
import modal from "@/components/modal-comp"
import accountAPI from "@/plugins/accountAPI"
import billingAPI from "@/plugins/billingAPI"
import craigslistAPI from "@/plugins/craigslistAPI"
import Buefy from "buefy"
import VueFormulate from "@braid/vue-formulate"

const config = require("@root/app_config")

Vue.use(Buefy)
Vue.use(VueFormulate)
Vue.use(accountAPI)
Vue.use(billingAPI)
Vue.use(craigslistAPI)
Vue.component("Modal", modal)

Vue.config.productionTip = false
Vue.config.debug = config.isDev

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")
