const defaultRoutes = [
  {
    path: "/",
    name: "index",
    component: () => lazyLoadView(import("@/views/index-page")),
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   component: () => lazyLoadView(import("@/views/default-pages/about-page")),
  // },
  // {
  //   path: "/contact",
  //   name: "contact",
  //   component: () => lazyLoadView(import("@/views/default-pages/contact-page")),
  // },
  // {
  //   path: "/privacy",
  //   name: "privacy",
  //   component: () => lazyLoadView(import("@/views/default-pages/privacy-page")),
  // },
  // {
  //   path: "/tos",
  //   name: "tos",
  //   component: () =>
  //     lazyLoadView(import("@/views/default-pages/terms-of-service-page")),
  // },
  {
    path: "/404",
    name: "pageNotFound",
    component: () =>
      lazyLoadView(import("@/views/default-pages/not-found-page")),
    props: true,
  },
  // {
  //   path: "/billing",
  //   name: "billing",
  //   component: () => lazyLoadView(import("@/views/billing-page")),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/billing/:planId",
  //   name: "billingWithSelectedPlan",
  //   component: () => lazyLoadView(import("@/views/billing-page")),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
]

// const accountRoutes = [
//   {
//     path: "/dashboard",
//     name: "primary-dashboard",
//     component: () => lazyLoadView(import("@/views/dashboard-page")),
//     meta: {
//       requiresAuth: true,
//     },
//   },
//   {
//     path: "/account",
//     name: "account",
//     component: () =>
//       lazyLoadView(import("@/views/account-management/account-page")),
//     meta: {
//       requiresAuth: true,
//     },
//   },
//   {
//     path: "/signup",
//     name: "signup",
//     component: () =>
//       lazyLoadView(import("@/views/account-management/signup-page")),
//     meta: {
//       redirectHomeIfAuthenticated: true,
//     },
//   },
//   {
//     path: "/login",
//     name: "login",
//     component: () =>
//       lazyLoadView(import("@/views/account-management/login-page")),
//     meta: {
//       redirectHomeIfAuthenticated: true,
//     },
//   },
//   {
//     path: "/accounts",
//     name: "accounts",
//     component: () =>
//       lazyLoadView(import("@/views/account-management/accounts-page")),
//     meta: {
//       requiresAuth: true,
//     },
//   },
//   {
//     path: "/permissions",
//     name: "permissions",
//     component: () =>
//       lazyLoadView(import("@/views/account-management/permissions-page")),
//     meta: {
//       requiresAuth: true,
//     },
//   },
//   {
//     path: "/confirm-account/:token",
//     name: "confirm",
//     component: () =>
//       lazyLoadView(import("@/views/account-management/confirm-page")),
//     props: true,
//   },
//   {
//     path: "/reset",
//     name: "reset",
//     component: () =>
//       lazyLoadView(import("@/views/account-management/init-reset-page")),
//     props: true,
//     meta: {
//       redirectHomeIfAuthenticated: true,
//     },
//   },
//   {
//     path: "/reset/:token",
//     name: "resetToken",
//     component: () =>
//       lazyLoadView(import("@/views/account-management/reset-page")),
//     meta: {
//       redirectHomeIfAuthenticated: true,
//     },
//   },
// ]

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
// component: () => import('@views/my-view')
function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require("@/views/default-pages/loading-page").default,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: require("@/views/default-pages/timeout-page").default,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    },
  })
}

export default [
  ...defaultRoutes,
  ...[
    {
      path: "/*",
      redirect: "404",
    },
  ],
]
