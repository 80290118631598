import { make } from "vuex-pathify"

const state = function() {
  return {
    showNavbar: true,
    showFooter: true,
    showSidebar: false,
  }
}

// automatically generate operations
const getters = { ...make.getters(state) }
const mutations = {
  ...make.mutations(state),

  reset(s) {
    Object.assign(s, state())
  },
}
const actions = { ...make.actions(state) }

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
