import axios from "axios"
import store from "@/store/index"
const config = require("@root/app_config")

// TODO: Config based on prod or not
const HTTP = () => {
  let base = axios.create({
    baseURL: config.APIHost,
    headers: {
      Authorization: `Bearer ${store.get("account/token")}`,
    },
  })

  // Add a response interceptor
  base.interceptors.response.use(
    function(response) {
      // Do something with response data

      return response
    },
    function(error) {
      // Do something with response error
      if (error.response && error.response.status === 401) {
        store.commit("account/reset")
        window.location.href = "/login"
      }
      return Promise.reject(error)
    }
  )
  return base
}

export default HTTP
