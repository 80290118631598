import store from "@/store/index"
import HTTP from "@/plugins/base"

const craiglistFunctions = {
  refreshSearches: function() {
    return HTTP()
      .get("/savedSearches")
      .then((res) => {
        store.set("craigslist/searches", res.data)
      })
  },
  createNewSearch: function() {
    // TODO: limit based on billing tier
    let payload = {
      location: "",
      category_code: "",
      filter: {
        query: "",
        search_titles: false,
        has_image: false,
        posted_today: false,
        bundle_duplicates: true,
      },
    }
    return HTTP()
      .post("/search", payload)
      .then((res) => {
        this.refreshSearches()
        console.log(res)
      })
  },
  updateSearch: function(payload) {
    return HTTP()
      .put("/search", this.cleanSearch(payload))
      .then((res) => {
        console.log(res)
      })
  },
  deleteSearch: function(search_id) {
    return HTTP().delete(`/search/${search_id}`)
  },
  getSearchUrl: function(payload) {
    return HTTP().post("/buildSearchUrl", this.cleanSearch(payload))
  },
  triggerSearch: function(search_id) {
    return HTTP().post(`/executeSearch/${search_id}`)
  },
  cleanSearch: function(payload) {
    if (payload.filter.search_distance == "") {
      payload.filter.search_distance = null
    }

    if (payload.filter.min_price == "") {
      payload.filter.min_price = null
    }

    if (payload.filter.max_price == "") {
      payload.filter.max_price = null
    }

    if (payload.filter.zip_code == "") {
      payload.filter.max_price = null
    }

    return payload
  },
}

export default {
  install: function(Vue) {
    Object.defineProperty(Vue.prototype, "$craigslistAPI", {
      value: { ...craiglistFunctions },
    })
  },
}
