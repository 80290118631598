<template>
  <nav v-if="showNavbar" class="navbar is-fixed-top" role="navigation">
    <modal
      :component="modalComp"
      :payload="{ postRoute: '/dashboard' }"
      @exitModal="closeModal"
    />

    <div class="navbar-brand">
      <router-link class="navbar-item" to="/" exact>
        <!-- <img src="/static/yourLogo.png" alt="Your Logo" width="112" height="28"> -->
        <h1 id="logo">DealScout</h1>
      </router-link>

      <div
        :class="{ 'is-active': showMobileNav }"
        class="navbar-burger burger"
        @click="toggleMobileNav"
      >
        <span />
        <span />
        <span />
      </div>
    </div>

    <div
      id="navMenu"
      :class="{ 'is-active': showMobileNav }"
      class="navbar-menu"
    >
      <div class="navbar-start">
        <router-link v-if="isLoggedIn" to="/dashboard" class="navbar-item"
        >Dashboard</router-link
        >

        <a v-if="!isLoggedIn" class="navbar-item" href="#" @click="toFeatures"
        >Features</a
        >
        <a v-if="!isLoggedIn" class="navbar-item" href="#" @click="toPriceTable"
        >Pricing</a
        >
      </div>

      <div class="navbar-end">
        <span v-if="!isLoggedIn" class="navbar-item">
          <a class="navbar-item button accNav is-info" @click="openSignup()"
          >Sign Up</a
          >
          <a class="navbar-item button accNav" @click="openLogin()">Log In</a>
        </span>

        <div v-if="isLoggedIn" class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link is-active">
            Account
          </a>
          <div class="navbar-dropdown ">
            <router-link to="/account" class="navbar-item">
              Edit Account
            </router-link>

            <router-link
              v-if="canManageBilling"
              to="/billing"
              class="navbar-item"
            >
              Billing
            </router-link>
            <hr class="navbar-divider" >
            <div class="navbar-item">
              <div>
                <small>
                  <a data-no-instant @click="logout()">Sign Out</a>
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import Vue from "vue"
import { get } from "vuex-pathify"
import authorization from "@/mixins/authorization.js"
const VueScrollTo = require("vue-scrollto")

export default {
  name: "Navbar",
  components: {},
  mixins: [authorization],
  data() {
    return {
      modalComp: null,
      showMobileNav: false,
      scrollToOptions: {
        offset: -60,
      },
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  computed: {
    showNavbar: get("ui/showNavbar"),
  },
  methods: {
    closeModal() {
      this.modalComp = null
    },
    openSignup() {
      this.modalComp = () => import("@/components/signup-form")
    },
    openLogin() {
      this.modalComp = () => import("@/components/login-form")
    },
    toPriceTable() {
      if (this.$router.currentRoute.path !== "/") {
        this.$router.push("/#").then(() => {
          Vue.nextTick().then(() => {
            setTimeout(function() {
              VueScrollTo.scrollTo("#pricing-table", 500, this.scrollToOptions)
            }, 450)
          })
        })
      } else {
        VueScrollTo.scrollTo("#pricing-table", 500, this.scrollToOptions)
      }
    },
    toFeatures() {
      if (this.$router.currentRoute.path !== "/") {
        this.$router.push("/#").then(() => {
          Vue.nextTick().then(() => {
            setTimeout(function() {
              VueScrollTo.scrollTo("#features", 500, this.scrollToOptions)
            }, 450)
          })
        })
      } else {
        VueScrollTo.scrollTo("#features", 500, this.scrollToOptions)
      }
    },
    logout() {
      this.$accountAPI.logout()
      this.$buefy.toast.open({
        duration: 1000,
        message: "Signed Out",
        position: "is-top",
        type: "is-dark",
      })
      this.$router.push("/")
    },
    toggleMobileNav() {
      this.showMobileNav = !this.showMobileNav
    },
  },
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
}
</script>

<style lang="scss">
nav {
  border-bottom: 1px solid $primary;
  right: 0;
  left: 0;
  z-index: 9;
  height: $stickyNavHeight;
}

.navbar-brand,
.navbar-tabs {
  min-height: 0;
  height: $stickyNavHeight;
}

#logo {
  font-weight: 700;
  font-size: 1.5em;
  color: $primary;
}

.is-active {
  .accNav {
    margin: 5px;
  }
}

.accNav {
  margin-left: 5px;
}
</style>
