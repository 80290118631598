import HTTP from "@/plugins/base"

const config = require("@root/app_config")

const billingFunctions = {
  stripePublishableKey: config.STRIPE_PUB_KEY,

  getAvailableSubscriptionPlans: function() {
    return HTTP().get("/subscription_plans")
  },
  startSubscription: function(token, subscriptionPlanId) {
    return HTTP().post("/subscriptions", {
      token: token,
      subscriptionPlanId: subscriptionPlanId,
    })
  },
  getDashboardUrl: function() {
    return HTTP().get("/billing_portal_url")
  },
}

export default {
  install: function(Vue) {
    Object.defineProperty(Vue.prototype, "$billingAPI", {
      value: billingFunctions,
    })
  },
}
