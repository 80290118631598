import Vue from "vue"
import Vuex from "vuex"
import pathify from "@/store/pathify"
import createPersistedState from "vuex-persistedstate"

import account from "@/store/account"
import ui from "@/store/ui"
import admin from "@/store/admin"
import craigslist from "@/store/craigslist"
const config = require("@root/app_config")

Vue.use(Vuex)

if (config.isDev) {
  pathify.debug()
}

const persistData = true

const store = {
  modules: {
    account: { ...account },
    ui: { ...ui },
    admin: { ...admin },
    craigslist: { ...craigslist },
  },
}

const storePlugins = persistData
  ? [createPersistedState(), pathify.plugin]
  : [pathify.plugin]

export default new Vuex.Store({
  plugins: storePlugins,
  strict: config.isDev,
  ...store,
})
