import store from "@/store/index"
import HTTP from "@/plugins/base"

function updateStoreData(accInfo) {
  if (accInfo.jwt) {
    store.set("account/token", accInfo.jwt)
  }

  if (accInfo.id) {
    store.set("account/isAuthenticated", true)
    store.set("account/id", accInfo.id)
  }

  if (accInfo.firstName) {
    store.set("account/firstName", accInfo.firstName)
  }

  if (accInfo.lastName) {
    store.set("account/lastName", accInfo.lastName)
  }

  if (accInfo.emailAddress) {
    store.set("account/emailAddress", accInfo.emailAddress)
  }

  if (accInfo.createdTime) {
    store.set("account/createdTime", accInfo.createdTime)
  }

  if (accInfo.modifiedTime) {
    store.set("account/modifiedTime", accInfo.modifiedTime)
  }

  if (accInfo.UUID) {
    store.set("account/UUID", accInfo.UUID)
  }

  if (accInfo.phoneNumber) {
    store.set("account/phoneNumber", accInfo.phoneNumber)
  }

  if (accInfo.isVerified) {
    store.set("account/isVerified", accInfo.isVerified)
  }

  if (accInfo.roles) {
    store.set("account/roles", accInfo.roles)
  }

  if (accInfo.stripePlan) {
    store.set("account/stripePlan", accInfo.stripePlan)
  }
}

const accFunctions = {
  login: function(payload) {
    return HTTP()
      .post("/login", {
        emailAddress: payload.emailAddress,
        password: payload.password,
      })
      .then((res) => {
        updateStoreData(res.data)
      })
  },
  signup: function(payload) {
    return HTTP()
      .post("/signup", {
        emailAddress: payload.emailAddress,
        password: payload.password,
        firstName: payload.firstName,
        lastName: payload.lastName,
      })
      .then((res) => {
        store.set("account/isAuthenticated", true)
        updateStoreData(res.data)
      })
  },
  getAccount: function(id = null) {
    if (id) {
      return HTTP().get(`/accounts/${id}`)
    } else {
      return HTTP()
        .get("/account")
        .then((res) => {
          store.set("account/isAuthenticated", true)
          updateStoreData(res.data)
        })
    }
  },
  updateAccount: function(payload, id = null) {
    if (id) {
      return HTTP().put(`/accounts/${id}`, payload)
    } else {
      return HTTP()
        .put("/account", payload)
        .then((res) => {
          updateStoreData(res.data)
        })
    }
  },
  deleteAccount: function(id = null) {
    if (id) {
      return HTTP().delete(`/accounts/${id}`)
    } else {
      return HTTP()
        .delete("/account")
        .then(() => {
          store.commit("account/reset")
        })
    }
  },
  getAccounts: function() {
    return HTTP()
      .get("/accounts")
      .then((res) => {
        return res.data
      })
  },
  initReset: function(emailAddress) {
    return HTTP().post(`/initiate-reset/${emailAddress}`)
  },
  initVerification: function(emailAddress) {
    return HTTP().post(`/resend-verification/${emailAddress}`)
  },
  confirmReset: function(token) {
    return HTTP()
      .post(`/confirm-reset/${token}`)
      .then((res) => {
        updateStoreData(res.data)
      })
  },
  validateAccount: function(token) {
    return HTTP()
      .post(`/confirm-account/${token}`)
      .then((res) => {
        updateStoreData(res.data)
      })
  },
  sendEmailToSiteAdmin: function(payload) {
    return HTTP().post("/contact-form", payload)
  },
  logout: function() {
    store.commit("account/reset")
    store.commit("ui/reset")
  },
  cleanData: function(payload) {
    let cleanedData = {
      errors: [],
    }

    if (payload.firstName) {
      cleanedData.firstName = payload.firstName
    }

    if (payload.lastName) {
      cleanedData.lastName = payload.lastName
    }

    if (payload.emailAddress) {
      cleanedData.emailAddress = payload.emailAddress
    }

    if (payload.isVerified) {
      cleanedData.isVerified = payload.isVerified
    }

    if (payload.phoneNumber) {
      cleanedData.phoneNumber = payload.phoneNumber
    }

    if (payload.roles) {
      cleanedData.roles = payload.roles.map((p) => p.id)
    }

    if (payload.password) {
      if (payload.password.length < 6) {
        payload.errors.push("Password does not meet length requirements")
      } else {
        cleanedData.password = payload.password
      }
    }

    if (payload.stripePlan) {
      cleanedData.stripePlan = payload.stripePlan
    }

    return cleanedData
  },
}

const permissionFunctions = {
  refreshRoles: function() {
    return HTTP()
      .get("/roles")
      .then((res) => {
        store.set("admin/roles", res.data)
      })
  },
  refreshPermissions: function() {
    return HTTP()
      .get("/permissions")
      .then((res) => {
        store.set("admin/permissions", res.data)
      })
  },
  updateRole: function(payload) {
    return HTTP()
      .put("/roles", payload)
      .then(() => {
        this.refreshRoles()
      })
  },
  deleteRole: function(roleId) {
    return HTTP()
      .delete(`/roles/${roleId}`)
      .then(() => {
        this.refreshRoles()
      })
  },
  createRole: function(payload) {
    return HTTP().post("/roles", payload)
  },
  createPermission: function(payload) {
    return HTTP().post("/permissions", payload)
  },
}

export default {
  install: function(Vue) {
    Object.defineProperty(Vue.prototype, "$accountAPI", {
      value: { ...accFunctions, ...permissionFunctions },
    })
  },
}
