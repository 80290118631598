<template>
  <div id="app">
    <GlobalNavbar />
    <transition name="fadePage" mode="out-in">
      <router-view id="appContent" :key="$route.fullPath" />
    </transition>
    <GlobalFooter />
  </div>
</template>

<script>
import GlobalNavbar from "@/components/navbar-comp"
import "@/styles/normalize.css"

export default {
  name: "App",
  components: {
    GlobalNavbar,
    GlobalFooter: () => import("@/components/footer-comp"),
    // SideBar: () => import("@/components/primary-sidebar"),
  },
  mixins: [],
  data() {
    return {}
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  computed: {},
  methods: {},
  filters: {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
}
</script>

<style lang="scss">
// setting Buefy variables from the _variables.scss file
@import "~bulma/sass/utilities/_all";
$primary-invert: findColorInvert($primary);
$colors: (
  "white": (
    $white,
    $black,
  ),
  "black": (
    $black,
    $white,
  ),
  "light": (
    $light,
    $light-invert,
  ),
  "dark": (
    $dark,
    $dark-invert,
  ),
  "primary": (
    $primary,
    $primary-invert,
  ),
  "info": (
    $info,
    $info-invert,
  ),
  "success": (
    $success,
    $success-invert,
  ),
  "warning": (
    $warning,
    $warning-invert,
  ),
  "danger": (
    $danger,
    $danger-invert,
  ),
);
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;
@import "~bulma";
@import "~buefy/src/scss/buefy";

html,
body {
  margin: 0;
  padding: 0;
  line-height: 1.5;
  box-sizing: border-box;
  overflow-x: hidden;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

a,
a:visited,
a:hover {
  text-decoration: none;
}

#app {
  margin: 0;
  padding: 0;
  z-index: 1;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  min-height: 94vh;
  min-height: calc(100vh - #{$stickyNavHeight + 2px});
}

#appContent {
  flex-grow: 1;
}

.fadePage-enter-active,
.fadePage-leave-active {
  transition: opacity 0.1s;
}
.fadePage-enter,
.fadePage-leave-to {
  opacity: 0;
}

// current route link
.router-link-active {
  color: $blue;
  font-weight: bold;
}

.formulate-input {
  input {
    font-size: 1em;
    padding: 8px;
    width: 100%;
  }

  .formulate-input-errors {
    margin-top: 5px;
    color: red;
  }
}

// Multi-select customization
.custom-multiselect {
  border: 1px solid #bcbcbc;
  border-radius: 3px;
  padding: 6px;
}

.multiselect__input {
  border: none;
  padding: inherit !important;
  width: 100%;
  display: inline;
  position: relative !important;
}

::placeholder,
.multiselect__placeholder {
  color: $grey-light !important;
}
input::-webkit-input-placeholder {
  color: $grey-light !important;
}
input:-moz-placeholder {
  color: $grey-light !important;
}

// for google-chrome input height
.multiselect--active {
  .multiselect__input {
    height: 35px;
  }
  ::placeholder {
    padding-left: 5px;
  }
}

.multiselect__content-wrapper {
  overflow-y: scroll;
  max-height: 400px !important;

  .multiselect__content {
    width: 100%;
  }

  .multiselect__element {
    width: 100% !important;
    padding: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 1em;
    border-bottom: 1px solid $grey-lighter;
    display: flex;

    .multiselect__option {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin: 0;
      padding: 0;
    }

    .multiselect__option span {
      flex-grow: 1;
      display: inline;
      width: 100%;
    }
  }

  .multiselect__element:hover {
    color: $primary;
    font-weight: 700;
    cursor: pointer;
  }
}
</style>
